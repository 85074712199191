import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { AccessTokenProvider } from "./context/AccessTokenContext";
import Home from "./pages/Home";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "home/",
    element: <Home />,
  },

]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AccessTokenProvider>
        <RouterProvider router={router} />
      </AccessTokenProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
