import * as React from "react";
import { TextField, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PASSWORD = "hoorah12345";

const AuthPage = () => {
  const navigate = useNavigate();

  const [password, setPassword] = React.useState("");
  return (
    <Box style={{ marginTop: 10 }} flexDirection="column">
      <Typography color="black">Enter Password</Typography>
      <TextField
        id="outlined-basic"
        label="Password"
        variant="outlined"
        onChange={e => {
          setPassword(e.target.value);
        }}
      />
      <Box flexDirection="row">
        <Button
          variant="contained"
          component="label"
          onClick={() => {
            if (password === PASSWORD) {
              navigate("/home");
            }
          }}
        >
          Enter
        </Button>
      </Box>
    </Box>
  );
};

export default AuthPage;
