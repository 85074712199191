import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import { Typography } from "@mui/material";
import AuthPage from "./pages/AuthPage";

function App() {
  return (
    <div className="App">
      <AuthPage />
    </div>
  );
}

export default App;
