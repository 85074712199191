import { useQuery } from "react-query";
import { getFeed } from "../../api/feed";
import { GET_FEED } from "../constants";

const useGetFeed = (tokenId: string) => {
  return useQuery([GET_FEED], () => getFeed(tokenId), {
    enabled: tokenId !== null,
  });
};

export default useGetFeed;
