import React from "react";
import { useAccessToken } from "../context/AccessTokenContext";
import useGetFeed from "../hooks/queries/useGetFeedItems";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { isImage } from "../utils/isImage";

const columns = [
  { field: "title", headerName: "Title", width: 150 },
  { field: "description", headerName: "Description" },
  {
    field: "media",
    headerName: "Media",
    width: 300,
    resizable: true,
    renderCell: (params: any) => {
      console.log({ params });
      const isImg = isImage(params.value);
      return isImg ? (
        <img
          src={params.value}
          style={{ objectFit: "contain", height: 400, width: 300 }}
        />
      ) : (
        <video>
          <source src={params.value} type="video/mp4" />
        </video>
      );
    },
  },
];

interface Item {
  description: string | null;
  id: number;
  media: string;
  title: string;
}

const FeedGrid = () => {
  const { accessToken } = useAccessToken();
  const { data, isLoading } = useGetFeed(accessToken ?? "");

  if (isLoading) {
    return <CircularProgress />;
  }

  return <DataGrid rows={data?.data} columns={columns} rowHeight={400} />;
};

export default FeedGrid;
