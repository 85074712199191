import * as React from "react";
import { TextField, Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useGetFeed from "../hooks/queries/useGetFeedItems";
import { useAccessToken } from "../context/AccessTokenContext";
import FeedGrid from "../components/FeedGrid";



//https://codesandbox.io/s/react-file-upload-parse-csv-09plq1?file=/src/App.tsx:251-301
const Home = () => {
  const [csvData, setCsvData] = React.useState<any[]>([]);
  const [filename, setFilename] = React.useState("");

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    reader.onload = evt => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      // const records = parse(result as string, {
      //   columns: ["id", "value"],
      //   delimiter: ";",
      //   trim: true,
      //   skip_empty_lines: true,
      // });
      // setCsvData(records);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Box style={{ marginTop: 10 }} flexDirection="column">
      <TextField id="outlined-basic" label="Title" variant="outlined" />
      <Box flexDirection="row">
        <Typography color="black">Media</Typography>
        <Typography color="black">{filename}</Typography>
        <Button variant="contained" component="label">
          Upload File
          <input type="file" hidden onChange={handleFileUpload} />
        </Button>

        <Button variant="contained" component="label" onClick={() => {}}>
          Submit
        </Button>
        <FeedGrid />
      </Box>
    </Box>
  );
};

export default Home;
