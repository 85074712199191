import * as React from "react";
import useGetAccountToken from "../hooks/queries/useGetAccessToken";

export const useAccessToken = () => React.useContext(AccessTokenContext);

interface AccessTokenContextType {
  accessToken: string | null;
}

export const AccessTokenContext = React.createContext<AccessTokenContextType>({
  accessToken: null,
});

export const AccessTokenProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, isLoading } = useGetAccountToken();

  const value = {
    accessToken: data?.data,
  };
  if (isLoading) {
    //TODO update loading screen
    return <div />;
  }

  console.log(data?.data);
  return (
    <AccessTokenContext.Provider value={value}>
      {children}
    </AccessTokenContext.Provider>
  );
};
